<template>
  <div class="w-100">
    <span class="text-muted">{{ title }}</span>
    <b-form-select v-model="selected">
      <b-form-select-option
        class="py-2"
        value=""
        disabled
      >
        Selecione
      </b-form-select-option>
      <b-form-select-option
        class="py-2"
        v-for="(item, idx) in options"
        :key="idx"
        :value="item.value"
      >
        {{ item.description }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
export default {
  
  props: {
    selected_option: null,
    title: String,
    options: Array,
  },
  watch:{
    selected_option:{
      handler(v){
        
      }
    }
  },
  computed: {
    selected: {
      get: function () {
        return this.selected_option;
      },
      set: function (val) {        
        this.$emit('update', val)
        this.$emit('changed', val)
      },
    },
  }  
};
</script>

<style>
</style>